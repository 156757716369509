import React, { useCallback, useState } from 'react'
import { type Field, useController, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button, FileUploader, type FileForm } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  HStack,
  Loader,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  NotificationContainer,
  VStack,
  Window,
  notify,
} from '@sevenrooms/core/ui-kit/layout'
import { Header, Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { appMessages } from './app.locales'
import { TrackingLinkImportService } from './TrackingLinkImport'
import { useStartTrackingLinksImportMutation, useStartTrackingLinksImportValidateMutation } from './useTrackingLinkImport'

interface WidgetProps {
  onClose: () => void
  field: Field<FileForm>
  venueId: string
  mediaUrl: string
}

export function Widget({ onClose, field, venueId, mediaUrl }: WidgetProps) {
  const uploadFileStep = 0
  const validationStep = 1
  const finalStep = 2
  const [currentImportStep, setCurrentImportStep] = useState(uploadFileStep)
  const { formatMessage } = useLocales()
  const file = useWatch(field)
  const fileSelected = !!file
  const accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const controller = useController(field)
  const [submitFileToValidate, { isLoading: isLoadingFile, data }] = useStartTrackingLinksImportValidateMutation()
  const [startImportMutation, { isLoading: isLoadingImport }] = useStartTrackingLinksImportMutation()
  const nextStep = useCallback(async () => {
    setCurrentImportStep(currentImportStep + 1)
  }, [currentImportStep])

  const uploadAndValidate = useCallback(async () => {
    if (!file?.fileData) {
      return
    }
    try {
      const uploaded = await TrackingLinkImportService.uploadTrakingLinkFile({ file: file?.fileData })
      await submitFileToValidate({
        venueId,
        blobstoreUploadKey: uploaded.postKey,
      }).unwrap()
      nextStep()
    } catch (e) {
      notify({ type: 'error', content: formatMessage(appMessages.genericServerError) })
    }
  }, [file?.fileData, formatMessage, nextStep, submitFileToValidate, venueId])

  const startImport = useCallback(async () => {
    try {
      if (data) {
        await startImportMutation({
          venueId,
          blobKeyCsv: data.blobKey,
        }).unwrap()
        nextStep()
      }
    } catch (e) {
      notify({ type: 'error', content: formatMessage(appMessages.genericServerError) })
    }
  }, [data, formatMessage, nextStep, startImportMutation, venueId])

  const newImport = useCallback(async () => {
    controller.field.onChange({ name: '', rawUrl: '', fileData: undefined })
    setCurrentImportStep(uploadFileStep)
  }, [controller.field])

  const renderLoading = () => (
    <>
      <ModalHeader onClose={onClose}>
        <ModalTitle title={formatMessage(appMessages.importTrackingLinks)} />
      </ModalHeader>
      <ModalBody>
        <VStack spacing="m">
          <Box pt="xxl" pb="xxl" borderColor="borders" borderRadius="s">
            <Loader size="m">
              <Text color="secondaryFont">{formatMessage(appMessages.processingData)}</Text>
            </Loader>
          </Box>
        </VStack>
      </ModalBody>
      <ModalFooter />
    </>
  )

  const renderUploadFile = () => (
    <>
      <ModalHeader onClose={onClose}>
        <ModalTitle title={formatMessage(appMessages.importTrackingLinks)} subTitle={formatMessage(appMessages.modalDescription)} />
      </ModalHeader>
      <ModalBody>
        <VStack spacing="s">
          <Header type="h3" data-test="modal-title">
            {formatMessage(appMessages.modalTemplates)}
          </Header>
          <HStack spacing="s" pt="s" pb="s">
            <Box>
              <Link isExternal to={`${mediaUrl}downloads/tracking_link_import_template/Tracking Links Import Template.xlsx`}>
                <Button size="s" variant="secondary" icon="VMSWeb-arrow-down" data-test="basic-template-button">
                  {formatMessage(appMessages.basicTemplate)}
                </Button>
              </Link>
            </Box>
          </HStack>
          <FileUploader
            field={field}
            accept={accept}
            onError={(content: React.ReactNode) => {
              notify({ type: 'error', content })
            }}
          />
          <Text color="secondaryFont">{formatMessage(appMessages.supportedFilesLabel)}</Text>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onClose} data-test="cancel-button">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary" disabled={!fileSelected} onClick={uploadAndValidate} data-test="next-button">
            {formatMessage(commonMessages.next)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </>
  )

  const renderFinalStep = () => (
    <>
      <ModalHeader onClose={onClose}>
        <ModalTitle title={formatMessage(appMessages.inProgress)} subTitle={formatMessage(appMessages.inProgressDescription)} />
      </ModalHeader>
      <ModalBody />
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={newImport} data-test="new-import-button">
            {formatMessage(appMessages.newImport)}
          </Button>
          <Button variant="primary" onClick={onClose} data-test="close-button">
            {formatMessage(commonMessages.close)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </>
  )
  const renderValidationStep = () => {
    if (data?.processingErrors) {
      notify({ type: 'error', content: formatMessage(appMessages.fileProcessingError) })
      setCurrentImportStep(uploadFileStep)
      return null
    }
    const validRows = (data?.totalRows || 0) - (data?.totalRowsWithErrors || 0)
    return (
      <>
        <ModalHeader onClose={onClose}>
          <ModalTitle title={formatMessage(appMessages.importOverview)} subTitle={formatMessage(appMessages.summaryOverview)} />
        </ModalHeader>
        <ModalBody>
          {!isLoadingFile ? (
            <VStack spacing="s">
              <Box mb="s" width="100%">
                <Text textStyle="body1Bold">{formatMessage(appMessages.fileInformation)}</Text>
              </Box>
              <HStack width="100%" justifyContent="space-between">
                <Text color="secondaryFont">{formatMessage(appMessages.fileName)}</Text>
                <Text color="secondaryFont">{data?.filename}</Text>
              </HStack>

              <HStack width="100%" justifyContent="space-between">
                <Text textStyle="body1Bold">{formatMessage(appMessages.totalRows)}</Text>
                <Text color="secondaryFont">{data?.totalRows}</Text>
              </HStack>

              <HStack width="100%" justifyContent="space-between">
                <HStack width="100%" justifySelf="left" spacing="xxs">
                  <Icon size="sm" name="VMSWeb-status-open" color="success" />
                  <Text color="secondaryFont">{formatMessage(appMessages.passedValidation)}</Text>
                </HStack>
                <Text color="secondaryFont">{validRows}</Text>
              </HStack>

              <HStack width="100%" justifyContent="space-between">
                <HStack width="100%" justifySelf="left" spacing="xxs">
                  <Icon size="sm" name="VMSWeb-status-open" color="error" />
                  <Text color="secondaryFont">{formatMessage(appMessages.totalRowsWithErrors)}</Text>
                </HStack>
                <Text color="secondaryFont">{data?.totalRowsWithErrors}</Text>
              </HStack>
            </VStack>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-between" alignItems="center" width="100%">
            {data?.filepath ? (
              <Link isExternal to={data?.filepath} download>
                {formatMessage(appMessages.downloadErrors)}
              </Link>
            ) : (
              <Box />
            )}
            <Box>
              <Button variant="tertiary" onClick={onClose} data-test="cancel-button">
                {formatMessage(commonMessages.cancel)}
              </Button>
              <Button variant="primary" onClick={startImport} data-test="import-button">
                {formatMessage(appMessages.import)}
              </Button>
            </Box>
          </HStack>
        </ModalFooter>
      </>
    )
  }

  const renderContent = (isLoading: boolean) => {
    if (isLoading) {
      return renderLoading()
    }
    switch (currentImportStep) {
      case uploadFileStep:
        return renderUploadFile()
      case finalStep:
        return renderFinalStep()
      case validationStep:
        return renderValidationStep()
      default:
        return renderUploadFile()
    }
  }
  return (
    <>
      <Window active>
        <Modal ariaLabel="Modal" width="100%" data-test="sr-import-tracking-link-modal">
          {renderContent(isLoadingFile || isLoadingImport)}
        </Modal>
      </Window>
      <NotificationContainer limit={3} />
    </>
  )
}
