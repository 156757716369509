import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '../../../../common/Button'
import {
  tryGetVenueSlugs,
  tryAddingVenueSlug,
  onNewTrackSave,
  newVenueSlugNevermind,
  tryDeletingSlug,
  updateField,
  showExperiencePageLinks,
  hideExperiencePageLinks,
} from '../actions/services'
import * as styles from '../assets/styles'
import { NewTrackingLink } from '../components/newtrackinglink'
import { TrackingLinksWidget } from '../components/trackinglinks'

import { copyToClipboard } from '@sevenrooms/core/ui-kit/utils'
import { merge } from 'svr/common/SRUtils'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'
import { TrackingLinkModalImport } from '@sevenrooms/tracking-links-imports'

class App extends Component {
  constructor(props) {
    super(props)
    this.handleDropDownClick = this.handleDropDownClick.bind(this)
    this.handleImportLinkClick = this.handleImportLinkClick.bind(this)
    this.state = { isOpen: false, showModal: false }
  }

  handleDropDownClick(e) {
    this.setState({ isOpen: !this.state.isOpen })
    e.stopPropagation()
  }

  componentWillMount() {
    this.props.tryGetVenueSlugs(this.props.venueId)
  }

  handleImportLinkClick() {
    this.setState({ ...this.state, showModal: true })
  }

  render() {
    return (
      <div>
        {this.state.showModal ? (
          <TrackingLinkModalImport
            venueId={this.props.venueId}
            onClose={() => this.setState({ ...this.state, showModal: false })}
            mediaUrl={this.props.MEDIA_URL}
          />
        ) : null}
        <div id="content-header">
          <h2>Tracking Links</h2>
          <div style={styles.actionButtons}>
            <Button label="Add Link" style={styles.addLink} onClick={this.props.tryAddingVenueSlug} />

            <div>
              <Button
                testId="sr-button-more_tracking_links"
                onClick={this.handleDropDownClick}
                onClickDropDown={this.handleDropDownClick}
                label="More"
                style={styles.dropdownBody}
                hover_style={styles.hoverStyle}
                prelabel_style={merge(VmsIconSizes.small, styles.prelabel)}
                includeDropDown
                dropdownlabel={VmsIcons.Chevron}
                dropdown_style={merge(VmsIconSizes.small, styles.dropDownStyle)}
              />
              <div style={styles.dropdownContainer}>
                {this.state.isOpen ? (
                  <div data-test="sr-dropdown-add_reservation" style={styles.dropdownMenu}>
                    <div
                      data-test="sr-button-import_links"
                      key="importLinks"
                      style={styles.dropdownItem}
                      onClick={this.handleImportLinkClick}
                    >
                      Import Links
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        {this.props.appState.newTracker ? (
          <NewTrackingLink
            data={this.props.appState}
            onSaveHandler={this.props.onSave}
            onNeverMindHandler={this.props.newVenueSlugNvm}
            updateNewLink={this.props.updateField}
            bookedbyOptions={this.props.bookedbyOptions}
            MEDIA_URL={this.props.MEDIA_URL}
          />
        ) : null}
        <div>
          <TrackingLinksWidget
            MEDIA_URL={this.props.MEDIA_URL}
            appState={this.props.appState}
            newTracker={this.props.newTracker}
            currentVenueUserSlug={this.props.currentVenueUserSlug}
            onDeleteHandler={this.props.onDeleteHandler}
            venueUserSlugs={this.props.venueUserSlugs}
            venuePromoterSlugs={this.props.venuePromoterSlugs}
            commonSlugs={this.props.commonSlugs}
            venueId={this.props.venueId}
            dining_widget_enabled={this.props.dining_widget_enabled}
            event_widget_enabled={this.props.event_widget_enabled}
            new_reservation_widget_enabled={this.props.new_reservation_widget_enabled}
            force_redirects_on_legacy_widget={this.props.force_redirects_on_legacy_widget}
            copyToClipboard={copyToClipboard}
            experiences={this.props.experiences}
            isShowExperiencePageLinks={this.props.appState.showExperiencePageLinks}
            showExperiencePageLinks={this.props.showExperiencePageLinks}
            hideExperiencePageLinks={this.props.hideExperiencePageLinks}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  venuePromoterSlugs: state.venueSlugs.venue_promoter_slugs,
  commonSlugs: state.venueSlugs.common_slugs,
  venueUserSlugs: state.venueSlugs.venue_user_slugs,
  currentVenueUserSlug: state.venueSlugs.current_user_slug,
  urlKey: state.venueSlugs.url_key,
  bookedbyOptions: state.venueSlugs.bookedby_users,
  appState: state.appState,
  experiences: state.venueSlugs.experiences || [],
})

const mapDispatchToProps = dispatch => ({
  updateField: e => {
    dispatch(updateField(e))
  },
  tryGetVenueSlugs: venueId => {
    dispatch(tryGetVenueSlugs(venueId))
  },
  tryAddingVenueSlug: () => {
    dispatch(tryAddingVenueSlug())
  },
  newVenueSlugNvm: () => {
    dispatch(newVenueSlugNevermind())
  },
  onSave: () => {
    dispatch(onNewTrackSave())
  },
  onDeleteHandler: (venueId, id) => {
    dispatch(tryDeletingSlug(venueId, id))
  },
  showExperiencePageLinks: () => {
    dispatch(showExperiencePageLinks())
  },
  hideExperiencePageLinks: () => {
    dispatch(hideExperiencePageLinks())
  },
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
